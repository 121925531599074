import axios from "axios";

// https://developer.settle.eu/handlers.html#outcome
export const outcomeDescriptions = {
  1003: `Waiting for customer ⏱`,
  2000: `Payment captured 🤑`,
  3008: `Payment authorized, ready for capture 💪`,
  4004: `No such customer 🤷‍♂️`,
  4019: `Merchant aborted payment before capture 😢`,
  5006: `Customer rejected payment request 😢`,
  5011: `Payment request expired ⏱`,
  5012: `Authorization not captured within 3 days ⏱`,
};

// https://developer.settle.eu/handlers.html#outcome
export const paymentStatusCodes = {
  PENDING: 1003,
  OK: 2000,
  AUTH: 3008,
  NOT_FOUND: 4004,
  ABORTED: 4019,
  REJECTED: 5006,
  REQUEST_EXPIRED: 5011,
  AUTH_EXPIRED: 5012,
};

// https://developer.settle.eu/handlers.html#outcome
export const paymentStatus = {
  OK: "ok",
  PENDING: "pending",
  AUTH: "auth",
  FAIL: "fail",
};

let requestCounter = 0;
export const doRequest = async function (method, endpoint, credentials, payload) {
  const { merchantId, secret } = credentials;
  console.info(`[${++requestCounter}] REQUEST`, method, endpoint, credentials, payload);
  let response;
  try {
    response = await axios({
      method,
      url: endpoint,
      data: payload,
      headers: {
        Accept: "application/vnd.mcash.api.merchant.v1+json",
        "Content-Type": "application/json",
        "X-Settle-Merchant": merchantId,
        Authorization: `SECRET ${secret}`,
      },
    });
    console.info(`[${requestCounter}] RESPONSE ${response.status}:`, response.data);
    return response;
  } catch (error) {
    if (error.response) {
      console.info(`[${requestCounter}] RESPONSE ${error.response.status}:`, error.response.data);
    } else {
      console.info(`[${requestCounter}] RESPONSE ${error}:`, error);
    }
    console.error(error);
    throw error;
  }
};

export const getShortlinkUrl = function (shortlinkId, argstring) {
  return `http://settle.eu/s/${shortlinkId}/${argstring}`;
};

export const getPaymentlinkUrl = function (transactionId) {
  return `http://settle.eu/p/${transactionId}`;
};

export const getCheckoutPortalUrl = function (transactionId, baseUrl) {
  return `https://${baseUrl}/checkout_portal/p/${transactionId}/`;
};

export const getFirebaseLongDynamicLinkUrl = function (acceptanceUrl, config, internal) {
  let scheme = "https";
  if (internal) scheme = config.ius;
  return [
    `${scheme}://${config.baseUrl}`,
    `?apn=${config.apn}`,
    `&ibi=${config.ibi}`,
    `&isi=${config.isi}`,
    `&ius=${config.ius}`,
    `&link=${encodeURIComponent(`https://${config.env}://qr/${acceptanceUrl}`)}`,
  ].join("");
};

export const getAppCustomProps = function (shortlinkUrl) {
  const customProps = `{"uri":"${shortlinkUrl}","trackConversion":false}`;
  return customProps.toString('base64');
};

export const getDeepLinkUrl = function (acceptanceUrl, config) {
  return [
    `${config.scheme}://${config.baseUrl}`,
    `?action=settle:scan`,
    `&customProps=${getAppCustomProps(acceptanceUrl)}`,
    `&dismissAfterInteraction=true`,
  ].join("");
};

export default {
  outcomeDescriptions,
  paymentStatus,
  paymentStatusCodes,
  doRequest,
  getFirebaseLongDynamicLinkUrl,
  getAppCustomProps,
  getDeepLinkUrl,
  getShortlinkUrl,
  getPaymentlinkUrl,
  getCheckoutPortalUrl,
};
