import React from 'react';

const StatusLog = ({ log }) => (
  <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3">
    <div className="box technical">
      <h2>📘 Merchant POS log</h2>
      <pre className="list">
        {log.map(({ id, logMessage }) => (
          <div key={id} className="row">
            {logMessage}
          </div>
        ))}
      </pre>
    </div>
  </div>
);

export default StatusLog;
