import React from 'react';

const MerchantViewSection = ({
  advancedOptions,
  currency,
  amount,
  message,
  posId,
  posTid,
  lastStatus,
  isExperimentShortlink,
  shortlinkId,
  argstring,
  paymentResolved,
  loadingRequest,
  autoCapture,
  handleChange,
  handleCreatePaymentRequest,
  handleAbort,
  handleCapture,
  handleRelease,
  handleStopDemo,
  handleResetOrder,
  handleResetCustomer,
}) => (
  <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3">
    <div className="pure-form pure-form-stacked">
      <div className="box">
        <div className="pure-control-group">
          <h2>🏬 Merchant view</h2>
          {!advancedOptions && (
            <p>Here we are preparing a demo order in our simplified test POS system, to be paid with Settle.</p>
          )}
        </div>
        <div className="pure-control-group">
          <div>
            <label>POS ID</label>
            <pre>{posId}</pre>
          </div>
          <div>
            <label>Order ID</label>
            <pre>{posTid}</pre>
          </div>
          <div>
            <label>Amount</label>
            <pre>
              {amount / 100} {currency}
            </pre>
          </div>
          <div>
            <label>Payment note</label> <pre>{message}</pre>
          </div>
        </div>
        <div></div>
        <div>
          <label>Status</label>
          <pre>{lastStatus}</pre>
        </div>

        {isExperimentShortlink && (
          <>
            <div>
              <label>Shortlink ID</label>
              {shortlinkId}
            </div>
            <div className="pure-control-group">
              <label htmlFor="argstring">Argstring for shortlink</label>
              <input
                className="pure-input-1"
                name="argstring"
                type="text"
                id="argstring"
                value={argstring}
                onChange={(event) => handleChange(event)}
              />
            </div>
            <span className="pure-form-message-inline">
              Optional payload at the end of the shortlink url. It&apos;s attatched to the callback and can be used in
              cases where the QR code generation happens in a separate system from the callback handling.
            </span>
          </>
        )}

        <div className="pure-control-group button-group">
          <button
            className="pure-button pure-button-primary"
            type="button"
            disabled={!paymentResolved || loadingRequest}
            onClick={(event) => handleCreatePaymentRequest(event)}
          >
            Request
          </button>
          <button
            className="pure-button pure-button-danger"
            type="button"
            disabled={paymentResolved || loadingRequest}
            onClick={(event) => handleAbort(event)}
          >
            Abort
          </button>
          <button
            className="pure-button"
            type="button"
            disabled={paymentResolved || loadingRequest}
            onClick={(event) => handleCapture(event)}
          >
            Capture
          </button>
          <button
            className="pure-button"
            type="button"
            disabled={paymentResolved || loadingRequest}
            onClick={(event) => handleRelease(event)}
          >
            Release
          </button>
        </div>

        {advancedOptions && (
          <div className="pure-control-group">
            <label htmlFor="autoCapture" className="pure-checkbox">
              <input
                type="checkbox"
                name="autoCapture"
                id="autoCapture"
                value="autoCapture"
                checked={autoCapture}
                onChange={(event) => handleChange(event)}
              />
              &nbsp;&nbsp;Auto capture
            </label>
            <span className="pure-form-message-inline">
              Capture authorized payments automatically. If not set, manual capture needs to happen within 72 hours or
              the funds will be refunded.
            </span>
          </div>
        )}

        <div className="pure-control-group button-group">
          <button
            disabled={!paymentResolved}
            className="pure-button"
            type="button"
            onClick={(event) => handleStopDemo(event)}
          >
            Back
          </button>
          <button
            className="pure-button"
            type="button"
            disabled={!paymentResolved}
            onClick={(event) => handleResetOrder(event)}
          >
            New order
          </button>
          <button
            disabled={!paymentResolved}
            className="pure-button"
            type="button"
            onClick={(event) => handleResetCustomer(event)}
          >
            New customer
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default MerchantViewSection;
