import React, { useState } from 'react';
import PropTypes from 'prop-types';
import config from 'config';

function Settings({
  environment,
  credentials,
  advancedOptions,
  isOutcomePolling,
  isOutcomeCallback,
  callbackUri,
  handleChange,
  handleChangeCredentials,
  addLogEntry,
  doRequest,
}) {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('Not tested');

  function handleTestCredentials() {
    setLoading(true);
    setStatus('');

    const { apiKey } = credentials[environment]; // TODO make apiKey state

    doRequest('GET', `/merchant/v1/api_key/${apiKey}/`, '')
      .then((response) => {
        console.info(response);
        setLoading(false);
        // TODO validation
        if (response?.data && response.data.has_secret === true) setStatus(`Key "${response.data.label}" is valid`);
        else setStatus('Invalid key');
      })
      .catch((error) => {
        setLoading(false);
        setStatus(error.message);
        addLogEntry(error.message);
      });
  }

  return (
    <div className="content">
      <h1>Settings</h1>
      <div className="pure-g">
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3">
          <div className="pure-form pure-form-stacked">
            <div className="box">
              <h2>Credentials</h2>
              <p>
                To access the Settle API, you need to set create an API Key in the{' '}
                <a href="https://business.sandbox.settle.eu" target="_blank" rel="noopener noreferrer">
                  Settle Business Portal
                </a>
                .
              </p>
              <p>
                If you haven&apos;t done so yet, we have some merchant credentials preset below that you can test with.
              </p>

              <div className="pure-control-group">
                <label htmlFor="environment">Environment</label>
                <select
                  id="environment"
                  name="environment"
                  value={environment}
                  onChange={(event) => handleChange(event)}
                >
                  {Object.keys(config).map((key) => (
                    <option key={key}>{key}</option>
                  ))}
                </select>
              </div>
              <div className="pure-control-group">
                <label>Merchant ID</label>
                <input
                  name={`merchantId`}
                  type="text"
                  value={credentials[environment].merchantId}
                  onChange={(event) => handleChangeCredentials(event)}
                />
              </div>
              <div className="pure-control-group">
                <label>User ID</label>
                <input
                  name={`apiKey`}
                  type="text"
                  value={credentials[environment].apiKey}
                  onChange={(event) => handleChangeCredentials(event)}
                />
              </div>
              <div className="pure-control-group">
                <label>Secret</label>
                <input
                  name={`secret`}
                  type="text"
                  value={credentials[environment].secret}
                  onChange={(event) => handleChangeCredentials(event)}
                />
              </div>
              <div className="pure-control-group button-group">
                <button
                  className="pure-button pure-button-primary"
                  type="button"
                  disabled={loading}
                  onClick={(event) => handleTestCredentials(event)}
                >
                  Test credentials
                </button>
              </div>
              <div id="credential-status">
                <p>{status} {loading && <span className="loader"></span>}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3">
          <div className="pure-form pure-form-stacked">
            <div className="box technical">
              <h2>Advanced Options</h2>
              <label htmlFor="advancedOptions" className="pure-checkbox">
                <input
                  type="checkbox"
                  name="advancedOptions"
                  id="advancedOptions"
                  value="advancedOptions"
                  checked={advancedOptions}
                  onChange={(event) => handleChange(event)}
                />
                &nbsp;&nbsp;Enable advanced options
              </label>

              {advancedOptions && (
                <div>
                  <h2>Outcome</h2>

                  <p>
                    To learn the outcome of a payment request, we can either poll for it or register a custom webhook
                    that handles status changes.
                  </p>
                  <p>Some events are only available via callback.</p>
                  <p>A callback webhook is preferred if the network policy of the POS environment allows it.</p>

                  <h3>Outcome options</h3>
                  <div className="pure-control-group">
                    <label htmlFor="polling" className="pure-radio">
                      <input
                        type="radio"
                        name="outcome"
                        id="polling"
                        value="polling"
                        checked={isOutcomePolling()}
                        onChange={(event) => handleChange(event)}
                      />
                      &nbsp;&nbsp;Polling
                    </label>
                    <span className="pure-form-message-inline">
                      Continuously poll for changes by calling GET on
                      <strong>/payment_request/&lt;tid&gt;/outcome/</strong>
                    </span>
                  </div>

                  <div className="pure-control-group">
                    <label htmlFor="callback" className="pure-radio">
                      <input
                        type="radio"
                        name="outcome"
                        id="callback"
                        value="callback"
                        checked={isOutcomeCallback()}
                        onChange={(event) => handleChange(event)}
                      />
                      &nbsp;&nbsp;Callback
                    </label>
                    <span className="pure-form-message-inline">
                      When the status of the payment request changes. The data in the &quot;object&quot; part of the
                      message is the same as calling GET on <strong>/payment_request/&lt;tid&gt;/outcome/</strong>
                    </span>
                  </div>

                  <div className="pure-control-group">
                    <label htmlFor="callbackUri">Callback URI</label>
                    <input
                      className="pure-input-1"
                      name="callbackUri"
                      type="text"
                      id="callbackUri"
                      value={callbackUri}
                      disabled={isOutcomePolling()}
                      onChange={(event) => handleChange(event)}
                    />
                    <span className="pure-form-message-inline">URL for webhook handling callbacks</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Settings.propTypes = {
  credentials: PropTypes.object.isRequired,
  environment: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeCredentials: PropTypes.func.isRequired,
  addAPILogEntry: PropTypes.func.isRequired,
  addLogEntry: PropTypes.func.isRequired,
};

export default Settings;
