import React from 'react';

const DemoMenu = ({
  isExperimentPhonenumber,
  isExperimentPaymentlink,
  isExperimentShortlinkReuse,
  isExperimentShortlinkSingle,
  isOutcomePolling,
  handleChange,
  handleNext,
}) => (
  <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3">
    <div className="pure-form pure-form-stacked">
      <div className="box">
        <h2>Choose demo</h2>

        <div className="pure-control-group">
          <label htmlFor="phonenumber" className="pure-radio">
            <input
              type="radio"
              name="experiment"
              id="phonenumber"
              value="phonenumber"
              checked={isExperimentPhonenumber}
              onChange={(event) => handleChange(event)}
            />
            &nbsp;&nbsp;Phonenumber
          </label>
          <span className="pure-form-message-inline">Send payment request to user phone.</span>
        </div>

        <div className="pure-control-group">
          <label htmlFor="paymentlink" className="pure-radio">
            <input
              type="radio"
              name="experiment"
              id="paymentlink"
              value="paymentlink"
              checked={isExperimentPaymentlink}
              onChange={(event) => handleChange(event)}
            />
            &nbsp;&nbsp;Paymentlink
          </label>
          <span className="pure-form-message-inline">Create payment request to be claimed by user.</span>
        </div>

        <div className="pure-control-group">
          <label htmlFor="shortlink_reuse" className="pure-radio">
            <input
              type="radio"
              name="experiment"
              id="shortlink_reuse"
              value="shortlink_reuse"
              checked={isExperimentShortlinkReuse}
              disabled={isOutcomePolling()}
              onChange={(event) => handleChange(event)}
            />
            &nbsp;&nbsp;Reuseable shortlink (callback only)
          </label>
          <span className="pure-form-message-inline">When user scans, create payment request for user.</span>
        </div>

        <div className="pure-control-group">
          <label htmlFor="shortlink_single" className="pure-radio">
            <input
              type="radio"
              name="experiment"
              id="shortlink_single"
              value="shortlink_single"
              checked={isExperimentShortlinkSingle}
              disabled={isOutcomePolling()}
              onChange={(event) => handleChange(event)}
            />
            &nbsp;&nbsp;Single use shortlink (callback only)
          </label>
          <span className="pure-form-message-inline">
            When user scans, create payment request based on information embeded in the Shortlink.
          </span>
        </div>

        <div>
          <div className="pure-control-group button-group">
            <button className="pure-button pure-button-primary" type="button" onClick={(event) => handleNext(event)}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default DemoMenu;
