import React from 'react';
import QRCode from 'react-qr-code';
import settle from 'settle';
import config from 'config';

const MerchantViewSection = ({
  advancedOptions,
  isExperimentPaymentlink,
  isExperimentShortlink,
  isExperimentPhonenumber,
  currentTid,
  acceptanceUrl,
  isClaimed,
  environment,
  phonenumber,
  callbackUri,
  handleChange,
}) => {
  const longDynamicLinkUrl = settle.getFirebaseLongDynamicLinkUrl(acceptanceUrl, config[environment].deeplink, false);
  const internalWebviewUrl = settle.getDeepLinkUrl(acceptanceUrl, config[environment].deeplink);
  const checkoutPortalUrl = settle.getCheckoutPortalUrl(currentTid, config[environment].portal);
  const callbackInterfaceUrl = callbackUri.replace('webhook.site/', 'webhook.site/#!');

  return (
    <>
      <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3">
        <div className="box">
          <h2>🙋‍♀️ Customer view</h2>
          {isExperimentPaymentlink && !currentTid && (
            <p>Waiting for merchant to create a payment request.</p>
          )}

          {((isExperimentPaymentlink && currentTid) || isExperimentShortlink) && (
            <>
              <div>
                <label>Acceptance URL:</label> <br />
                <pre>{acceptanceUrl}</pre>
              </div>
              <div>
                <label>Claimed:</label>
                <pre>{isClaimed ? 'Yes' : 'No'}</pre>
              </div>
              <pre>
                <div className={isClaimed ? 'claimed-label' : 'ready-label'}>{isClaimed ? 'Claimed' : 'Ready'}</div>
                {acceptanceUrl && (
                  <QRCode size={200} className={isClaimed ? 'claimed-border' : 'ready-border'} value={acceptanceUrl} />
                )}
              </pre>
              <div>
                <a className="pure-button pure-button-secondary" target="_new" href={longDynamicLinkUrl}>
                  Pay with Settle
                </a>
              </div>
              {advancedOptions && (
                <p>
                  <a className="pure-button pure-button-secondary" target="_new" href={internalWebviewUrl}>
                    Pay with Settle (internal)
                  </a>
                </p>
              )}
              <p>
                <a className="pure-button pure-button-secondary" target="_new" href={checkoutPortalUrl}>
                  Go to Settle Checkout
                </a>
              </p>
              <p></p>
              {isExperimentShortlink && (
                <aside className="warn-block">
                  To simulate merchant a backend, the following manual steps are required:
                  <ol>
                    <li>scan the static QR without a payment request</li>
                    <li>
                      manually retrieve the &quot;scan token&quot; via the{' '}
                      <a href={callbackInterfaceUrl}>callback URL</a>
                    </li>
                    <li>set &quot;customer&quot; field of the request to use this token</li>
                  </ol>
                </aside>
              )}
            </>
          )}

          {isExperimentPhonenumber && (
            <div className="pure-form pure-form-stacked">
              <div className="pure-control-group">
                <label htmlFor="phonenumber">Recipient phone number</label>
                <input
                  className="pure-input-2-5"
                  name="phonenumber"
                  type="text"
                  id="phonenumber"
                  value={phonenumber}
                  onChange={(event) => handleChange(event)}
                />
                <span className="pure-form-message-inline">
                  Needs to be <a href="https://en.wikipedia.org/wiki/MSISDN">MSISDN</a> formatted
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MerchantViewSection;
