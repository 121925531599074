import React from 'react';

const ShortlinkSection = ({
  loadingShortlink,
  shortlinkId,
  handleFetchShortlinks,
  handlePrevious,
  handleNext,
}) => (
  <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3">
    <div className="pure-form pure-form-stacked">
      <div className="box">
        <h2>Shortlinks</h2>
        <div>
          <p>
            A Shortlink is a placeholder and trigger point for payment request creation. Shortlinks can only handle one
            customer at a time, but can be reused my many different customers. As the POS usually only service one
            customer at a time, having one dedicated Shortlink per POS is usually fine.
          </p>
          <p>
            To avoid unnecessary API calls during the payment cycle it&apos;s best to create dedicated Shortlinks as
            part of the POS setup phase.{' '}
          </p>
          <p>
            In this demo we will first try to retrieve any current links for this merchant and otherwise create a new
            one with the desired callback URL.
          </p>
          <div className="pure-control-group button-group">
            {!loadingShortlink && !shortlinkId && (
              <button
                className="pure-button pure-button-primary"
                type="button"
                onClick={(event) => handleFetchShortlinks(event)}
              >
                Retrieve shortlinks
              </button>
            )}
            {loadingShortlink && <div className="loader"></div>}
            {!loadingShortlink && shortlinkId && <span>Done!</span>}
          </div>
          <p></p>
          <div className="pure-control-group button-group">
            <button className="pure-button" type="button" onClick={(event) => handlePrevious(event)}>
              Back
            </button>
            <button
              className="pure-button pure-button-primary"
              type="button"
              disabled={!shortlinkId}
              onClick={(event) => handleNext(event)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ShortlinkSection;
