import React from 'react';

const ApiLog = ({ loadingRequest, log }) => (
  <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3">
    <div className="box technical">
      <h2>📕 API request log {loadingRequest && <div className="loader loader-small"></div>}</h2>
      <div className="status">
        {log.map(({ id, method, endpoint, status, duplicates }) => (
          <div key={id} className="row pure-g">
            <div className="pure-u-2-24">{duplicates > 0 && <span className="label">{duplicates}</span>}</div>
            <div className="pure-u-22-24">
              <strong>{method}</strong> {status && `${status} `}
              {endpoint}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default ApiLog;
