import React from 'react';

const DemoHelpSection = ({
  isExperimentPhonenumber,
  isExperimentPaymentlink,
  isExperimentShortlinkReuse,
  isExperimentShortlinkSingle,
}) => (
  <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-3-3">
    <div className="box">
      {isExperimentPhonenumber && (
        <div>
          <h3>Phonenumber</h3>
          <p>
            Send payment request directly to the customer device when POS have access to customer data (or can easily
            accept phone number as input from customer).
          </p>
          <ol>
            <li>POS creates local order with unique id.</li>
            <li>POS creates payment request for customer phone number.</li>
            <li>Settle pushes payment request to the customer.</li>
            <li>Settle calls back to POS with outcome.</li>
          </ol>
          <p>
            <em>Example:</em> Vending machine, web-shop, recurring payments
          </p>
        </div>
      )}

      {isExperimentPaymentlink && (
        <div>
          <h3>Paymentlink</h3>
          <p>
            The easiest method, as we gather order details and then create payment request that we can then link to for
            some user to later claim.
          </p>
          <ol>
            <li>POS creates local order with unique id.</li>
            <li>POS creates payment request.</li>
            <li>POS creates unique Paymentlink QR code.</li>
            <li>Customer scans QR code.</li>
            <li>Settle pushes payment request to the customer.</li>
            <li>Settle calls back to POS with outcome.</li>
          </ol>
          <p>
            <em>Example:</em> Modern cash registers, mobile pos, card terminals, web-shops.
          </p>
        </div>
      )}

      {isExperimentShortlinkReuse && (
        <div>
          <h3>Shortlink (reusable)</h3>
          <p>Mainly used for printed QR codes, on posters and newspaper ads.</p>
          <p>Payment request sent when a user interacts with a Shortlink that was created during POS setup.</p>
          <p>
            The Shortlink acts as a proxy between the user and a payment, and can be reused as many times as needed,
            even by diffrent users.
          </p>
          <p>QR created when POS device is turned on or registered.</p>
          <ol>
            <li>Setup: POS creates Shortlink.</li>
            <li>Setup: POS creates Shortlink QR code.</li>
            <li>Customer scans reusable Shortlink QR code.</li>
            <li>Settle calls back to POS with customer identifier.</li>
            <li>POS creates payment request for customer identifier.</li>
            <li>Settle pushes payment request to the customer.</li>
            <li>Settle calls back to POS with outcome.</li>
          </ol>

          <p>
            <em>Example:</em> POS device without customer facing display, requiring pre-printed QR code.
          </p>
        </div>
      )}

      {isExperimentShortlinkSingle && (
        <div>
          <h3>Shortlink (single use)</h3>
          <p>
            Useful pattern to pass along a local transaction id when the device handling the customer and is not
            receiving the callback.
          </p>
          <p>
            The local transaction id is passed along by Settle and can be picked up by centralised middleware to
            understand what POS device and purchase the request originated from.
          </p>
          <p>QR created when POS device is turned on or registered.</p>
          <ol>
            <li>Setup: POS creates Shortlink.</li>
            <li>Setup: POS creates Shortlink QR code with local id.</li>
            <li>Customer scans Shortlink QR code.</li>
            <li>Settle calls back to POS with customer identifier and local id.</li>
            <li>POS creates payment request for customer identifier.</li>
            <li>Settle pushes payment request to the customer.</li>
            <li>Settle calls back to POS with outcome.</li>
          </ol>
          <p>
            <em>Example:</em> Card terminals
          </p>
        </div>
      )}
    </div>
  </div>
);

export default DemoHelpSection;
