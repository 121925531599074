import ShortUniqueId from "short-unique-id";

export const experiments = {
  PHONENUMBER: "phonenumber",
  PAYMENTLINK: "paymentlink",
  SHORTLINK_REUSE: "shortlink_reuse",
  SHORTLINK_SINGLE: "shortlink_single",
};

export const steps = {
  EXPERIMENTS: 1,
  SHORTLINK: 2,
  ORDER: 3,
};

export function setStoredSetting(name, value) {
  localStorage.setItem(`settle-api-utility.config.${name}`, value);
}

const catalogFruits = [
  { name: "🍐 Pear      ", price: 2 },
  { name: "🍎 Apple   ", price: 2 },
  { name: "🍌 Banana ", price: 3 },
  { name: "🍇 Grapes ", price: 4 },
  { name: "🥭 Mango  ", price: 5 },
  { name: "🍒 Cherry  ", price: 6 },
];

const catalogFoodDrinks = [
  { name: "🍪 Cookie  ", price: 1 },
  { name: "🥯 Bagel   ", price: 2 },
  { name: "🍩 Doughnut", price: 3 },
  { name: "🍰 Cake    ", price: 4 },
  { name: "🍟 Fries   ", price: 4 },
  { name: "🍔 Burger  ", price: 6 },
  { name: "🥪 Sandwich", price: 4 },
  { name: "🥗 Salad   ", price: 4 },
  { name: "🥛 Milk    ", price: 2 },
  { name: "🧃 Juice   ", price: 3 },
  { name: "🍷 Wine    ", price: 6 },
  { name: "🍺 Beer    ", price: 6 },
  { name: "🍹 Drink   ", price: 8 },
];

export function getStoredSetting(name, defaultValue) {
  let storedValue = localStorage.getItem(`settle-api-utility.config.${name}`);
  if (storedValue === "true") {
    storedValue = true;
  } else if (storedValue === "false") {
    storedValue = false;
  }
  const value = storedValue !== null ? storedValue : defaultValue;
  console.info(`Option ${name}:`, value);
  return value;
}

export const settingsStore = {
  set: setStoredSetting,
  get: getStoredSetting,
}

function getUniqueId() {
  return new ShortUniqueId({ length: 10 })()
}

export function getProducts(catalog) {

  function getRandomInt(max = 5) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  function formattedProduct(name, amount, price) {
    const padPrice = price < 10 ? ` ${price}` : price;
    return `${amount}\t${name}\t${padPrice} kr\n`;
  }

  function getRandomProduct(catalog) {
    return catalog[Math.floor(Math.random() * catalog.length)]
  }

  const wishList = [];
  while (wishList.length < getRandomInt() + 2) {
    const randomItem = getRandomProduct(catalog);
    if (!wishList.includes(randomItem)) {
      wishList.push(randomItem);
    }
  }

  const shoppingCart = wishList.map((item) => {
    const amount = getRandomInt(3) + 1;
    return { name: item.name, amount, price: item.price * amount };
  });

  const formattedList = shoppingCart
    .map(({ name, amount, price }) => {
      return formattedProduct(name, amount, price);
    })
    .join("");

  const totalCost =
    shoppingCart.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.price;
    }, 0) * 100;

  return { formattedList, totalCost };
}

export const productFactory = {
  getUniqueId,
  getProducts,
  getFruits: () => getProducts(catalogFruits),
  getFoodDrinks: () => getProducts(catalogFoodDrinks),
}
