import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  <div className="content">
    <h1>API Utility</h1>
    <div className="pure-g">
      <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3">
        <div className="pure-form pure-form-stacked">
          <div className="box">
            <h2>Experiments</h2>
            <ul>
              <li>
                <Link to="/payment-flows">Payment flows</Link>
              </li>
              <li>Login flows (coming soon)</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3">
        <div className="pure-form pure-form-stacked">
          <div className="box">
            <h2>Functions (coming soon)</h2>
            <ul>
              <li>Payment requests</li>
              <li>Permission requests</li>
              <li>Userinfo</li>
              <li>POS</li>
              <li>Shortlinks</li>
              <li>Reports</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>;
}

export default Home;
