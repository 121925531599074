import React from 'react';
import PropTypes from 'prop-types';
import settle from 'settle';
import { experiments, steps, productFactory, settingsStore } from 'utilities';
import PaymentRequestSection from './sections/PaymentRequestSection';
import MerchantViewSection from './sections/MerchantViewSection';
import CustomerViewSection from './sections/CustomerViewSection';
import DemoMenuSection from './sections/DemoMenuSection';
import DemoHelpSection from './sections/DemoHelpSection';
import ShortlinkSection from './sections/ShortlinkSection';
import StatusLog from './StatusLog';
import ApiLog from './ApiLog';


class DemoPage extends React.Component {
  constructor(props) {
    super(props);
    const products = productFactory.getFoodDrinks();

    this.state = {
      shortlinkId: null,
      shortlinkIdWithCallback: null,
      shortlinkIdNoCallback: null,
      currentStatus: null,
      currentStatusCode: null,
      step: steps.EXPERIMENTS,
      loadingCredentials: false,
      loadingShortlink: false,
      loadingRequest: false,
      isClaimed: false,
      expiresIn: 300,
      amount: products.totalCost,
      customer: '',
      paymentResolved: true,
      experiment: settingsStore.get('experiment', experiments.PHONENUMBER),
      phonenumber: settingsStore.get('phonenumber', ''),
      autoCapture: settingsStore.get('autoCapture', true),
      successReturnUri: settingsStore.get('successReturnUri', ''),
      failureReturnUri: settingsStore.get('failureReturnUri', ''),
      additionalAmount: settingsStore.get('additionalAmount', 0),
      additionalEdit: settingsStore.get('additionalEdit', false),
      currency: settingsStore.get('currency', 'NOK'),
      action: settingsStore.get('action', 'SALE'),
      posId: settingsStore.get('posId', 'demoPos1'),
      argstring: settingsStore.get('argstring', 'demoPos1'),
      requiredScope: settingsStore.get('requiredScope', ''),
    };
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        settingsStore.set(name, value);

        if (name === 'callbackUri') {
          this.setState({ shortlinkIdWithCallback: '' });
        }

        if (name === 'argstring') {
          this.updatePaymentOptions();
        }

        if (name === 'phonenumber') {
          this.setState({ customer: `msisdn:${value}` });
        }
      }
    );
  }

  async handleCreatePaymentRequest() {
    const { experiment, acceptanceUrl } = this.state;
    const tid = await this.createPaymentRequest();
    let newUrl = acceptanceUrl;

    if (experiment === experiments.PAYMENTLINK) {
      newUrl = settle.getPaymentlinkUrl(tid);
    }

    this.setState({
      loadingRequest: false,
      acceptanceUrl: newUrl,
    });
  }

  resetCustomer() {
    const { experiment, phonenumber, shortlinkIdWithCallback, shortlinkIdNoCallback } = this.state;
    const { isOutcomeCallback } = this.props;
    let shortlinkId = null;
    let customer = '';

    switch (experiment) {
      case experiments.PHONENUMBER:
        customer = `msisdn:${phonenumber}`;
        break;
      case experiments.SHORTLINK_REUSE:
      case experiments.SHORTLINK_SINGLE:
        if (isOutcomeCallback()) {
          if (!this.state.shortlinkIdWithCallback) {
            throw Error('Unable to get or create shortlink');
          }
          shortlinkId = shortlinkIdWithCallback;
        } else {
          shortlinkId = shortlinkIdNoCallback;
        }
        customer = `token:`;
        break;
      case experiments.PAYMENTLINK:
      default:
    }

    this.setState({
      customer,
      shortlinkId,
      loadingRequest: false,
    });
  }

  async capturePayment(tid) {
    const { doRequest } = this.props;
    await doRequest('PUT', `/merchant/v1/payment_request/${tid}/`, { action: 'capture' });
  }

  async abortPayment(tid) {
    const { addLogEntry, doRequest } = this.props;
    const { currentTid } = this.state;
    await doRequest('PUT', `/merchant/v1/payment_request/${tid}/`, { action: 'abort' });
    addLogEntry(`Aborted payment request ${currentTid}`);
  }

  async releasePayment(tid) {
    const { doRequest } = this.props;
    await doRequest('PUT', `/merchant/v1/payment_request/${tid}/`, { action: 'release' });
  }

  handleCapture() {
    // TODO
    const { currentTid } = this.state;
    this.capturePayment(currentTid);
  }

  handleAbort() {
    // TODO
    const { currentTid } = this.state;
    this.abortPayment(currentTid);
  }

  handleRelease() {
    // TODO
    const { currentTid } = this.state;
    this.releasePayment(currentTid);
  }

  prepareExperiment() {
    this.setState({ argstring: this.state.posId });
    this.handleResetOrder();
    this.resetCustomer();
  }

  handleNext() {
    const { step, experiment } = this.state;
    let nextStep = step + 1;
    if (
      step === steps.SHORTLINK - 1 &&
      (experiment === experiments.PHONENUMBER || experiment === experiments.PAYMENTLINK)
    )
      nextStep += 1;
    if (nextStep === steps.ORDER) this.prepareExperiment();
    this.setState({ step: nextStep });
  }

  handlePrevious() {
    const { step, experiment } = this.state;
    let prevStep = step - 1;
    if (step === steps.ORDER) {
      this.prepareExperiment();
      if (experiment === experiments.PHONENUMBER || experiment === experiments.PAYMENTLINK) prevStep -= 1;
    }
    this.setState({ step: prevStep });
  }

  async handleFetchShortlinks() {
    const { addLogEntry, doRequest } = this.props;
    try {
      this.setState({ loadingShortlink: true });
      const response = await doRequest('GET', '/merchant/v1/shortlink/', {});
      const urlList = response.data.uris;
      const deferreds = urlList.map((url) => this.setShortlinks(url));
      addLogEntry(`Analysing ${urlList.length} existing shortlinks`);

      Promise.all(deferreds).then(() => {
        this.createMissingLinks();
      });
    } catch (error) {
      console.error(error);
      addLogEntry(error.message);
    }
  }

  async setShortlinks(uri) {
    const { addLogEntry, doRequest } = this.props;
    const parts = uri.split('/');
    const linkId = parts[parts.length - 2];
    const response = await doRequest('GET', `/merchant/v1/shortlink/${linkId}/`, {});
    const link = response.data;

    if (!link.callback_uri && !this.state.shortlinkIdNoCallback) {
      this.setState({ shortlinkIdNoCallback: link.id });
      addLogEntry(`Using shortlink ${link.id} for outcome polling`);
    }
    if (link.callback_uri === this.props.callbackUri) {
      this.setState({ shortlinkIdWithCallback: link.id });
      addLogEntry(`Using shortlink ${link.id} for outcome callback ${link.callback_uri}`);
    }
  }

  async createMissingLinks() {
    const { addLogEntry, doRequest, callbackUri, isOutcomeCallback } = this.props;

    if (!this.state.shortlinkIdWithCallback) {
      try {
        const response = await doRequest('POST', '/merchant/v1/shortlink/', { callback_uri: callbackUri });
        const link = response.data;
        addLogEntry(`Created shortlink ${link.id} with callback ${callbackUri}`);
        this.setState({ shortlinkIdWithCallback: link.id });
      } catch (error) {
        addLogEntry(`Could not create shortlink`);
      }
    }
    if (!this.state.shortlinkIdNoCallback) {
      try {
        const response = await doRequest('POST', '/merchant/v1/shortlink/', { callback_uri: null });
        const link = response.data;
        console.info(`Created shortlink ${link.id} without callback`);
      } catch (error) {
        addLogEntry(`Could not create link`);
      }
    }
    const shortlinkId = isOutcomeCallback() ? this.state.shortlinkIdWithCallback : this.state.shortlinkIdNoCallback;

    this.setState(
      {
        loadingShortlink: false,
        shortlinkId,
      },
      this.updateAcceptanceUrl
    );
  }

  updateAcceptanceUrl() {
    console.info('Updateing acceptance url');
    const { argstring, posTid, experiment, shortlinkId, currentTid } = this.state;
    let acceptanceUrl;

    if (experiment === experiments.SHORTLINK_REUSE) {
      acceptanceUrl = settle.getShortlinkUrl(shortlinkId, argstring);
    }

    if (experiment === experiments.SHORTLINK_SINGLE) {
      acceptanceUrl = settle.getShortlinkUrl(shortlinkId, posTid);
      this.setState({
        argstring: posTid,
      });
    }

    if (experiment === experiments.PAYMENTLINK) {
      acceptanceUrl = settle.getPaymentlinkUrl(currentTid);
    }
    this.setState({
      acceptanceUrl,
    });
  }

  handleStopDemo() {
    this.handleResetOrder();
    this.handlePrevious();
    this.setState({
      paymentResolved: true,
      statusLog: [],
    });
  }

  /*
    PENDING: `Waiting for customer ⏱`,
    OK: `Payment captured 🤑`,
    AUTH: `Payment authorized, ready for capture 💪`,
    NOT_FOUND: `No such customer 🤷‍♂️`,
    ABORTED: `Merchant aborted payment before capture 😢`,
    REJECTED: `Customer rejected payment request 😢`,
    REQUEST_EXPIRED: `Payment request expired ⏱`,
    AUTH_EXPIRED: `Authorization not captured within 3 days ⏱`,
  */
  async checkOutcome() {
    const { addLogEntry, doRequest } = this.props;
    const { paymentResolved, currentTid, autoCapture, isClaimed } = this.state;

    if (!paymentResolved) {
      try {
        const response = await doRequest('GET', `/merchant/v1/payment_request/${currentTid}/outcome/`);
        const { status, status_code, customer } = response.data;

        if (status === undefined) throw Error('Invalid response');
        
        addLogEntry(settle.outcomeDescriptions[status_code]);

        let state = {
          currentStatus: status,
          currentStatusCode: status_code,
          customer,
        };

        if (customer && !customer.includes(':')) {
          state.customer = `msisdn:${customer}`;
        }

        if (status === settle.paymentStatus.OK || status === settle.paymentStatus.FAIL) {
          state.paymentResolved = true;
        }

        if (!isClaimed && customer?.includes('token')) {
          addLogEntry('Customer scanned QR code');
          state.isClaimed = true;
        }

        if (status === settle.paymentStatus.AUTH) {
          if (autoCapture) {
            this.capturePayment(currentTid);
          }
        }
        this.setState(state, () => setTimeout(() => this.checkOutcome(), 3000));
      } catch (error) {
        console.error(error.message);
        setTimeout(() => this.checkOutcome(), 10000);
        return;
      }
    }
  }

  handleResetOrder() {
    const { addLogEntry } = this.props;
    const { experiment, shortlinkId } = this.state;
    this.resetPayment();
    addLogEntry('Order unpaid');
    const products = productFactory.getFoodDrinks();
    const orderId = productFactory.getUniqueId();
    let newState = {
      posTid: orderId,
      amount: products.totalCost,
      message: `${products.formattedList}\nEnjoy! Thank you for choosing us.`,
    };

    if (experiment === experiments.SHORTLINK_SINGLE) {
      const newUrl = settle.getShortlinkUrl(shortlinkId, orderId);
      newState.argstring = orderId;
      newState.acceptanceUrl = newUrl;
    }

    this.setState(newState);
  }

  handleResetCustomer() {
    this.handleResetOrder();
    this.resetCustomer();
  }

  resetPayment() {
    const { experiment } = this.state;
    const isExperimentShortlink =
      experiment === experiments.SHORTLINK_REUSE || experiment === experiments.SHORTLINK_SINGLE;
    let state = {
      paymentResolved: true,
      loadingRequest: false,
      isClaimed: false,
      currentTid: null,
      currentStatus: null,
      currentStatusCode: null,
    };

    if (!isExperimentShortlink) {
      // Reset QR code
      const canvas = document.getElementById('qr');
      // eslint-disable-next-line no-self-assign
      if (canvas) canvas.width = canvas.width; // quick and dirty reset of QR canvas

      state.acceptanceUrl = '';
    }
    // reset payment state
    this.setState(state);
  }

  updatePaymentOptions() {
    const { shortlinkId, argstring } = this.state;
    const newUrl = settle.getShortlinkUrl(shortlinkId, argstring);
    this.setState({ acceptanceUrl: newUrl });
  }

  async createPaymentRequest() {
    const { addLogEntry, doRequest, callbackUri, isOutcomeCallback } = this.props;
    const {
      action,
      amount,
      additionalAmount,
      additionalEdit,
      currency,
      message,
      customer,
      currentTid,
      posId,
      posTid,
      requiredScope,
      successReturnUri,
      failureReturnUri,
      expiresIn,
    } = this.state;

    let body = {
      action: action,
      amount: parseInt(amount),
      currency: currency,
      pos_id: posId,
      pos_tid: posTid,
      text: message,
      additional_amount: parseInt(additionalAmount),
      additional_edit: additionalEdit,
      allow_credit: true,
      expires_in: expiresIn,
      //display_message_uri': 'Fooobar?',
      // 'line_items': [ {
      //   'product_id': 'abc123',
      //   'description': 'Blue jeans',
      //   'vat_rate': '0.00',
      //   'quantity': '2',
      //   'currency': 'NOK',
      //   'item_cost': 500,
      //   'total': 1000
      // }]
    };
    if (requiredScope !== '') body['required_scope'] = requiredScope;
    if (customer !== '') body['customer'] = customer;
    if (isOutcomeCallback()) body['callback_uri'] = callbackUri;
    if (successReturnUri !== '') body['success_return_uri'] = successReturnUri;
    if (failureReturnUri !== '') body['failure_return_uri'] = failureReturnUri;

    const response = await doRequest('POST', '/merchant/v1/payment_request/', body);
    const newTransactionId = response.data.id;
    if (newTransactionId !== currentTid) {
      addLogEntry(`Created payment request ${newTransactionId}`);
      this.setState(
        {
          currentTid: newTransactionId,
          paymentResolved: false,
        },
        this.checkOutcome
      );
    } else {
      addLogEntry(`Payment already exist ${newTransactionId}`);
    }
    return newTransactionId;
  }

  render() {
    const { environment, advancedOptions, isOutcomePolling, isOutcomeCallback, statusLog, apiLog, callbackUri } =
      this.props;

    const {
      step,
      posId,
      posTid,
      amount,
      action,
      message,
      customer,
      currency,
      argstring,
      expiresIn,
      successReturnUri,
      failureReturnUri,
      isClaimed,
      experiment,
      phonenumber,
      shortlinkId,
      autoCapture,
      acceptanceUrl,
      requiredScope,
      additionalEdit,
      additionalAmount,
      loadingShortlink,
      paymentResolved,
      currentStatus,
      currentStatusCode,
      currentTid,
      loadingRequest,
    } = this.state;

    const isExperimentPhonenumber = experiment === experiments.PHONENUMBER;
    const isExperimentPaymentlink = experiment === experiments.PAYMENTLINK;
    const isExperimentShortlinkReuse = experiment === experiments.SHORTLINK_REUSE;
    const isExperimentShortlinkSingle = experiment === experiments.SHORTLINK_SINGLE;
    const isExperimentShortlink = isExperimentShortlinkReuse || isExperimentShortlinkSingle;

    const getTitle = () => {
      switch (experiment) {
        case experiments.PHONENUMBER:
          return 'Demo - Phonenumber';
        case experiments.PAYMENTLINK:
          return 'Demo - Paymentlink';
        case experiments.SHORTLINK_REUSE:
          return 'Demo - Reuseable shortlink';
        case experiments.SHORTLINK_SINGLE:
          return 'Demo - Single use shortlink';
        default:
          return 'Demo';
      }
    };

    return (
      <div className="content">
        <h1>{getTitle()}</h1>
        <div className="pure-g">
          {step === steps.EXPERIMENTS && (
            <DemoMenuSection
              isExperimentPhonenumber={isExperimentPhonenumber}
              isExperimentPaymentlink={isExperimentPaymentlink}
              isExperimentShortlinkReuse={isExperimentShortlinkReuse}
              isExperimentShortlinkSingle={isExperimentShortlinkSingle}
              isOutcomePolling={isOutcomePolling}
              handleChange={this.handleChange.bind(this)}
              handleNext={this.handleNext.bind(this)}
            />
          )}

          {step === steps.EXPERIMENTS && (
            <DemoHelpSection
              isExperimentPhonenumber={isExperimentPhonenumber}
              isExperimentPaymentlink={isExperimentPaymentlink}
              isExperimentShortlinkReuse={isExperimentShortlinkReuse}
              isExperimentShortlinkSingle={isExperimentShortlinkSingle}
            />
          )}

          {step === steps.SHORTLINK && (
            <ShortlinkSection 
              loadingShortlink={loadingShortlink}
              shortlinkId={shortlinkId}
              handleFetchShortlinks={this.handleFetchShortlinks.bind(this)}
              handlePrevious={this.handlePrevious.bind(this)}
              handleNext={this.handleNext.bind(this)}
            />
          )}

          {step === steps.ORDER && (
            <MerchantViewSection
              advancedOptions={advancedOptions}
              currency={currency}
              amount={amount}
              message={message}
              posId={posId}
              posTid={posTid}
              lastStatus={statusLog.length > 0 ? statusLog[0].logMessage : ''}
              isExperimentShortlink={isExperimentShortlink}
              shortlinkId={shortlinkId}
              argstring={argstring}
              paymentResolved={paymentResolved}
              loadingRequest={loadingRequest}
              autoCapture={autoCapture}
              handleChange={this.handleChange.bind(this)}
              handleCreatePaymentRequest={this.handleCreatePaymentRequest.bind(this)}
              handleAbort={this.handleAbort.bind(this)}
              handleCapture={this.handleCapture.bind(this)}
              handleRelease={this.handleRelease.bind(this)}
              handleStopDemo={this.handleStopDemo.bind(this)}
              handleResetOrder={this.handleResetOrder.bind(this)}
              handleResetCustomer={this.handleResetCustomer.bind(this)}
            />
          )}

          {step === steps.ORDER && (
            <CustomerViewSection
              advancedOptions={advancedOptions}
              isExperimentPaymentlink={isExperimentPaymentlink}
              isExperimentShortlink={isExperimentShortlink}
              isExperimentPhonenumber={isExperimentPhonenumber}
              currentTid={currentTid}
              acceptanceUrl={acceptanceUrl}
              callbackUri={callbackUri}
              isClaimed={isClaimed}
              phonenumber={phonenumber}
              environment={environment}
              handleChange={this.handleChange.bind(this)}
            />
          )}

          {step === steps.ORDER && (
            <PaymentRequestSection
              advancedOptions={advancedOptions}
              currentTid={currentTid}
              currentStatusCode={currentStatusCode}
              currentStatus={currentStatus}
              action={action}
              expiresIn={expiresIn}
              currency={currency}
              amount={amount}
              message={message}
              additionalAmount={additionalAmount}
              additionalEdit={additionalEdit}
              isOutcomeCallback={isOutcomeCallback}
              callbackUri={callbackUri}
              customer={customer}
              isOutcomePolling={isOutcomePolling}
              posId={posId}
              posTid={posTid}
              requiredScope={requiredScope}
              successReturnUri={successReturnUri}
              failureReturnUri={failureReturnUri}
              handleChange={this.handleChange.bind(this)}
            />
          )}

          {step >= steps.ORDER && (
            <>
              <StatusLog log={statusLog} />
              <ApiLog log={apiLog} />
            </>
          )}
        </div>
      </div>
    );
  }
}

DemoPage.propTypes = {
  credentials: PropTypes.object.isRequired,
  environment: PropTypes.string.isRequired,
  handleChangeCredentials: PropTypes.func.isRequired,
  addLogEntry: PropTypes.func.isRequired,
};

export default DemoPage;
