import React from 'react';

const PaymentRequestSection = ({
  advancedOptions,
  currentTid,
  currentStatusCode,
  currentStatus,
  action,
  expiresIn,
  currency,
  amount,
  message,
  additionalAmount,
  additionalEdit,
  isOutcomeCallback,
  callbackUri,
  customer,
  isOutcomePolling,
  posId,
  posTid,
  requiredScope,
  successReturnUri,
  failureReturnUri,
  handleChange,
}) => (
  <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3">
    <div className="pure-form pure-form-stacked">
      <div className="box technical">
        <h2>📗 Payment request</h2>
        {!advancedOptions && (
          <p>
            The customer can either reject or authorize a payment request. An authorization is valid for 3 days, but can
            be reauthorized before it expires to be valid for 3 new days. Once the payment is authorized, it can be
            captured to be included in the next settlement.
          </p>
        )}
        <div className="pure-control-group" style={{
          marginBottom: '40px'
        }}>
          <div>
            <label className="inline">TID:</label> {currentTid || 'N/A'}
          </div>
          <div>
            <label className="inline">Status Code:</label> {currentStatusCode || 'N/A'}{' '}
          </div>
          <div>
            <label className="inline">Status Type:</label>
            <span style={{ textTransform: 'uppercase' }}> {currentStatus || 'N/A'}</span>
          </div>
          <div>
            <label className="inline">Customer:</label> {customer || 'N/A'}
          </div>
        </div>

        {advancedOptions && (
          <div className="container" id="transaction">
            <h3>Request options</h3>

            <div className="pure-control-group">
              <label htmlFor="action">Action</label>
              <input
                className="pure-input-1-4"
                name="action"
                type="text"
                id="action"
                value={action}
                onChange={(event) => handleChange(event)}
              />
            </div>
            <div className="pure-control-group">
              <label htmlFor="currency">Currency</label>
              <input
                className="pure-input-1-4"
                name="currency"
                type="text"
                id="currency"
                value={currency}
                onChange={(event) => handleChange(event)}
              />
              <span className="pure-form-message-inline">
                Three (3) chars following <a href="https://en.wikipedia.org/wiki/ISO_4217">ISO 4217</a>
              </span>
            </div>
            <div className="pure-control-group">
              <label htmlFor="amount">Amount {currency} (in cents)</label>
              <input
                className="pure-input-1-4"
                name="amount"
                type="number"
                id="amount"
                value={amount}
                onChange={(event) => handleChange(event)}
              />
              <span className="pure-form-message-inline">The base amount of the payment.</span>
            </div>
            <div className="pure-control-group">
              <label htmlFor="additionalAmount">Additional amount {currency} (in cents)</label>
              <input
                className="pure-input-1-4"
                name="additionalAmount"
                type="number"
                id="additionalAmount"
                value={additionalAmount}
                onChange={(event) => handleChange(event)}
              />
              <span className="pure-form-message-inline">Typically a cash withdrawal or gratuity</span>
            </div>
            <div className="pure-control-group">
              <label htmlFor="additionalEdit">
                <input
                  type="checkbox"
                  name="additionalEdit"
                  id="additionalEdit"
                  value="additionalEdit"
                  checked={additionalEdit}
                  onChange={(event) => handleChange(event)}
                />
                &nbsp;&nbsp;Additional edit
              </label>
              <span className="pure-form-message-inline">
                Whether user is allowed to add additional amount for gratuity or similar.
              </span>
            </div>
            <div className="pure-control-group">
              <label htmlFor="customer">Customer</label>
              <input
                className="pure-input-2-4"
                name="customer"
                type="text"
                id="customer"
                value={customer || ''}
                onChange={(event) => handleChange(event)}
              />
            </div>
            <div className="pure-control-group">
              <label htmlFor="message">Payment note</label>
              <textarea
                className="pure-input-1"
                name="message"
                id="message"
                rows="6"
                value={message}
                onChange={(event) => handleChange(event)}
              />
              <span className="pure-form-message-inline">Message explaning what was paid for.</span>
            </div>
            <div className="pure-control-group">
              <label htmlFor="expiresIn">Expiration (in seconds)</label>
              <input
                className="pure-input-1-4"
                name="expiresIn"
                type="number"
                id="expiresIn"
                value={expiresIn}
                onChange={(event) => handleChange(event)}
              />
              <span className="pure-form-message-inline">How long before the reqeuest expires.</span>
            </div>
            <div className="pure-control-group">
              <label htmlFor="posId">POS ID</label>
              <input
                className="pure-input-1-4"
                name="posId"
                type="text"
                id="posId"
                value={posId}
                onChange={(event) => handleChange(event)}
              />
              <span className="pure-form-message-inline">
                The POS this payment request originates from, used for informing users.
              </span>
            </div>
            <div className="pure-control-group">
              <label htmlFor="posTid">POS Transaction ID</label>
              <input
                className="pure-input-3-4"
                name="posTid"
                type="text"
                id="posTid"
                value={posTid}
                onChange={(event) => handleChange(event)}
              />
              <span className="pure-form-message-inline">
                Local transaction id for POS. This must be unique for the POS.
              </span>
            </div>
            <div className="pure-control-group">
              <label htmlFor="requiredScope">Required scope</label>
              <input
                className="pure-input-3-4"
                name="requiredScope"
                type="text"
                id="requiredScope"
                value={requiredScope}
                onChange={(event) => handleChange(event)}
              />
              <span className="pure-form-message-inline">
                Set this field to ask for data from the user together with the payment request.
              </span>
            </div>
            <div className="pure-control-group">
              <label htmlFor="callbackUri">Callback URI</label>
              <input
                className="pure-input-1"
                name="callbackUri"
                type="text"
                id="callbackUri"
                value={isOutcomeCallback ? callbackUri : ''}
                onChange={(event) => handleChange(event)}
                disabled={isOutcomePolling()}
              />
              <span className="pure-form-message-inline">URL for server handling callbacks.</span>
            </div>
            <div className="pure-control-group">
              <label htmlFor="successReturnUri">Success URI</label>
              <input
                className="pure-input-1"
                name="successReturnUri"
                type="text"
                id="successReturnUri"
                value={successReturnUri}
                onChange={(event) => handleChange(event)}
              />
              <span className="pure-form-message-inline">Return URL if payment successful.</span>
            </div>
            <div className="pure-control-group">
              <label htmlFor="failureReturnUri">Failure URI</label>
              <input
                className="pure-input-1"
                name="failureReturnUri"
                type="text"
                id="failureReturnUri"
                value={failureReturnUri}
                onChange={(event) => handleChange(event)}
              />
              <span className="pure-form-message-inline">Return URL if payment failed.</span>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default PaymentRequestSection;
