const config = {
  prod: {
    name: 'Production',
    portal: 'portal-dot-settle-core-prod.appspot.com',
    deeplink: {
      scheme: 'settle',
      env: 'settle',
      baseUrl: 'get.settle.eu',
      apn: 'eu.settle.app',
      ibi: 'eu.settle.app',
      isi: '1440051902',
      ius: 'eu.settle.app.firebaselink',
    }
  },
  sandbox: {
    name: 'Sandbox',
    portal: 'portal-dot-settle-core-demo.appspot.com',
    deeplink: {
      scheme: 'settle-sandbox',
      env: 'settle-demo',
      baseUrl: 'settledemo.page.link',
      apn: 'eu.settle.app.sandbox',
      ibi: 'eu.settle.app.sandbox',
      isi: '1453180781',
      ius: 'eu.settle.app.firebaselink',
    },
    testApiKey: '76pj520h',
    testMerchantId: 'f1645s',
    testSecret: '.IIIXVCFdey3Bo5HD4gQh2+y_Ce9C.qodl_9FB.CQAWHua_rCrpCn3N-YwagvGY7',
  },
  qa: {
    name: 'QA',
    portal: 'portal-dot-settle-core-qa.appspot.com',
    deeplink: {
      scheme: 'settle-qa',
      env: 'settle-qa',
      baseUrl: 'settleqa.page.link',
      apn: 'io.auka.aukasales.qa',
      ibi: 'io.auka.aukasales.qa',
      isi: '1402708733',
      ius: 'eu.settle.app.firebaselink',
    }
  },
  dev: {
    name: 'Development',
    portal: 'portal-dot-settle-core-dev.appspot.com',
    deeplink: {
      scheme: 'settle-dev',
      env: 'settle-dev',
      baseUrl: 'settledev.page.link',
      apn: 'io.auka.aukasales.dev',
      ibi: 'io.auka.aukasales.dev',
      isi: '1395496531',
      ius: 'eu.settle.app.firebaselink',
    }
  }
};

export default config;