import React from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, Link, useLocation } from 'react-router-dom';
import logo from './settle-logo.png';
import settle from './settle';
import config from './config';
import Experiment from './demo/DemoPage';
import Settings from './settings/Settings';
import Home from './Home';
import { experiments, steps, settingsStore } from './utilities';
import set from 'lodash/set';

import './App.css';

function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

function defaultCredentials(environment) {
  return {
    merchantId: settingsStore.get(`credentials.${environment}.merchantId`, ''),
    apiKey: settingsStore.get(`credentials.${environment}.apiKey`, ''),
    secret: settingsStore.get(`credentials.${environment}.secret`, ''),
  };
}

class App extends React.Component {
  constructor(props) {
    super(props);
    const environment = settingsStore.get('environment', 'sandbox');
    const credentials = {
      prod: defaultCredentials('prod'),
      sandbox: {
        merchantId: settingsStore.get(`credentials.${environment}.merchantId`, config['sandbox'].testMerchantId),
        apiKey: settingsStore.get(`credentials.${environment}.apiKey`, config['sandbox'].testApiKey),
        secret: settingsStore.get(`credentials.${environment}.secret`, config['sandbox'].testSecret),
      },
      qa: defaultCredentials('qa'),
      dev: defaultCredentials('dev'),
    };

    this.state = {
      environment,
      credentials,
      statusLog: [],
      apiLog: [],
      callbackUri: settingsStore.get('callbackUri', 'https://webhook.site/171f8257-382b-4c52-98a1-fbd94760e0ab'),
      outcome: settingsStore.get('outcome', 'polling'),
      advancedOptions: settingsStore.get('advancedOptions', false),
    };
  }

  isOutcomeCallback() {
    return this.state.outcome === 'callback';
  }

  isOutcomePolling() {
    return this.state.outcome === 'polling';
  }

  addLogEntry(logMessage) {
    const { statusLog, lastStatusMessage } = this.state;
    if (logMessage === lastStatusMessage) {
      return;
    }
    console.info(`log > ${logMessage}`);
    statusLog.unshift({ id: statusLog.length, logMessage });
    this.setState({ statusLog, lastStatusMessage: logMessage });
  }

  addAPILogEntry(method, endpoint, bodyObject, status) {
    const body = JSON.stringify(bodyObject);

    let { apiLog } = this.state;
    if (apiLog.length > 0) {
      const previous = apiLog[0];
      const isMatching =
        previous.method === method &&
        previous.endpoint === endpoint &&
        previous.body === body &&
        previous.status === status;

      if (isMatching) {
        apiLog[0].duplicates = previous.duplicates + 1;
        return;
      }
    }

    const logEntry = { id: apiLog.length, method, endpoint, body, status, duplicates: 0 };
    apiLog.unshift(logEntry);
    this.setState({ apiLog });
  }

  async doRequest(method, endpoint, body) {
    const { environment, credentials } = this.state;
    const { merchantId, secret } = credentials[environment];

    if (!endpoint.includes('outcome')) this.setState({ loadingRequest: true });
    try {
      const response = await settle.doRequest(
        method,
        `/api/${environment}${endpoint}`,
        {
          merchantId,
          secret,
        },
        body
      );
      this.setState({ loadingRequest: false });
      if (!endpoint.includes('outcome')) this.addAPILogEntry(method, endpoint, body, response.status);
      return response;
    } catch (error) {
      let logEntry;
      console.info('Check error:', error.response);
      if (error.response) {
        logEntry = `${error.response.statusText}. ${
          error.response.data.error_description ? error.response.data.error_description : ''
        }`;
      } else {
        logEntry = `${error.message}`;
      }
      this.addLogEntry(logEntry);
      this.addAPILogEntry(method, endpoint, body, error.response && error.response.status);
      throw error;
    }
  }

  handleChangeCredentials(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const { credentials, environment } = this.state;

    this.setState({
      credentials: set(credentials, `${environment}.${name}`, value),
    });

    settingsStore.set(`credentials.${environment}.${name}`, value);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        settingsStore.set(name, value);

        if (name === 'outcome' && value === 'callback') {
          this.setState({ shortlinkId: this.state.shortlinkIdWithCallback });
        }

        if (name === 'outcome' && value === 'polling') {
          this.setState({
            shortlinkId: this.state.shortlinkIdNoCallback,
            experiment: experiments.PHONENUMBER,
            step: steps.EXPERIMENTS,
          });
        }
      }
    );
  }

  render() {
    const { credentials, environment, advancedOptions, callbackUri, statusLog, apiLog } = this.state;
    const { merchantId, apiKey } = credentials[environment];
    const foobarContext = {
      credentials,
      environment,
      advancedOptions,
      statusLog,
      apiLog,
      callbackUri,
      handleChange: this.handleChange.bind(this),
      handleChangeCredentials: this.handleChangeCredentials.bind(this),
      addAPILogEntry: this.addAPILogEntry.bind(this),
      addLogEntry: this.addLogEntry.bind(this),
      isOutcomePolling: this.isOutcomePolling.bind(this),
      isOutcomeCallback: this.isOutcomeCallback.bind(this),
      doRequest: this.doRequest.bind(this),
    };

    return (
      <main className="App">
        <Router>
          <header className="App-header">
            <div className="App-content">
              <Link to="/">
                <img src={logo} className="App-logo hide-sm" alt="logo" />
              </Link>
              <nav className="pure-menu pure-menu-horizontal">
                <ul className="pure-menu-list">
                  <li className="pure-menu-item">
                    <NavLink className="pure-menu-link" to="/">
                      Demo
                    </NavLink>
                  </li>
                  <li className="pure-menu-item">
                    <NavLink className="pure-menu-link" to="/settings">
                      Settings
                    </NavLink>
                  </li>
                </ul>
              </nav>
              <div className="App-controls hide-md">
                <span>{process.env.REACT_APP_VERSION}</span>
                <span>{environment}</span>
                <span>
                  {merchantId}&nbsp;/&nbsp;{apiKey}
                </span>
              </div>
            </div>
          </header>

          <Routes>
            <Route path="/" element={<Experiment {...foobarContext} />} />
            <Route path="settings" element={<Settings {...foobarContext} />} />
            <Route path="home" element={<Home {...foobarContext} />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Router>
      </main>
    );
  }
}

export default App;
